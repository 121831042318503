<script>
import BaseProfile from "./BaseProfile";
export default {
  data: (vm) => ({
    base_url: "/backend/buildings/",
    mongo_url: "/mongo/building_transcripts/",
    export_url:"/backend/buildings/",
    mongo_owners_url:"/mongo/building_owners/",
  }),
  extends: BaseProfile,
};
</script>
